import React from 'react';
import {Button} from 'antd';

const SectionThird = props => {
    return (
        <div className='section' id='section3'>
            <div className='info'>
                <Button ghost shape="round"
                        icon="up"
                        onClick={() => props.fullpageApi.moveSectionUp()}
                        size='large'>Späť</Button>
            </div>
        </div>
    );
};

export default SectionThird;
