import React from 'react';
import './App.css';
import Fullpage from "../fullpage/Fullpage";

const App = () => {
    return (
        <Fullpage/>
    );
};

export default App;
