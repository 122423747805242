import React from 'react';

const Header = props => {
    const domain = document.location.host;
    return (
        <div id='header'>
            <div id='logo'><img src='/assets/images/logo.png' alt="home" className="nav-icon"/></div>
            <div id='domain'>{domain}</div>
        </div>
    );
};

export default Header;
